import React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import TitleArea from '../containers/contact/title-area'
import BannerArea from '../containers/contact/banner-area'
import FormArea from '../containers/contact/form-area'

class ContactPage extends React.Component {
  render() {
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    return (
      <Layout>
        <SEO title="Contact" />
        <Header authorData={author.node}/>
        <div className="main-content">
            <TitleArea/>
            <BannerArea/>
            <FormArea/>
        </div>
        <Footer/>
      </Layout>
    )
  }
}

export default ContactPage
 
export const contactUsQuery = graphql`
  query {
    allContentfulPerson(filter: { id: { eq: "801d0181-aa93-5da9-82f1-fae3b71554af" } }) {
      edges {
        node {
          ...ContentfulPersonField
        }
      }
    }
  }
`